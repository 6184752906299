import { FormEvent, useEffect, useState } from 'react';
import './App.css';
import { Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [code, setCode] = useState<string>("")

  const [token, setToken] = useState<string | null>()
  const [state, setState] = useState<string | null>()
  const [error, setError] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)

  const submit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      setLoading(true)
      const response = await fetch(`${process.env.REACT_APP_BACKEND}`, {
        method: "POST",
        body: JSON.stringify({
          token,
          state,
          code
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (response.status === 200) {
        const data = await response.json()
        window.location.href = data.redirectUrl
      } else if (response.status === 401) {
        setError("Code is invalid")
        setLoading(false)
      } else {
        setError("Unknown error occured")
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    setToken(queryParams.get("token"))
    setState(queryParams.get("state"))
  }, [setToken, setState])

  return (
    <div className="App">
      <div className="col-sm-6 mx-auto my-3 text-center">
        <h3>One-time code</h3>
      </div>
      <div className="col-sm-1 mx-auto my-3">
        <Form onSubmit={submit} className="text-center d-flex flex-column gap-3">
          <Form.Control type="text" name="code" size="lg" maxLength={6} onChange={e => setCode(e.target.value)} className="text-center" autoFocus />
          { error && <div className="text-danger">{error}</div>}
          {
            loading ?
              <Button disabled>Verifying...</Button> :
              <Button type="submit" disabled={code.length !== 6}>Verify</Button>
          }
        </Form>
      </div>
    </div>
  );
}

export default App;
